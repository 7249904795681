import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    primary: '#4173b9',
    darkgray: '#121212',
  },
  fonts: {
    body: "'Libre Franklin', system-ui, Roboto, Arial, sans-serif",
    heading: "'Libre Franklin', system-ui, Roboto, Arial, sans-serif",
    mono: 'Menlo, monospace',
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: '0',
        fontFamily: 'heading',
        fontWeight: '600',
        letterSpacing: '0.2rem',
        alignItems: 'center',
        textTransform: 'uppercase',
      },
      variants: {
        solid: {
          fontSize: 'sm',
          bg: 'primary',
          color: 'white',
          _hover: {
            bg: 'black',
          },
        },
        solid_secondary: {
          fontSize: 'sm',
          bg: 'black',
          color: 'white',
          _hover: {
            bg: 'primary',
          },
        },
        outline: {
          _hover: {
            color: 'gray.900',
            bg: 'gray.200',
          },
        },
      },
    },
  },
  styles: {
    global: {
      html: { boxSizing: 'border-box' },
      body: {
        color: 'text',
      },
      main: {
        width: 'full',
        flex: `1 1 auto`,
      },
      '[data-reach-skip-link]': {
        background: 'white',
        left: '-99999em',
        padding: '0.5em',
        position: 'absolute',
        zIndex: '1000',
        textDecoration: 'none',
      },
      '[data-reach-skip-link]:focus': {
        left: '0.5em',
        top: '0.5em',
      },
      a: {
        color: 'primary',
        textDecoration: 'underline',
      },
      p: {
        color: 'text',
        fontSize: ['md'],
        mb: ['3', '3', '6'],
      },
      ul: {
        listStylePosition: 'outside',
        mb: ['3', '3', '6'],
        ml: ['6', '6', '9'],
      },
      ol: {
        listStylePosition: 'outside',
        mb: ['3', '3', '6'],
        ml: ['6', '6', '9'],
      },
      li: {
        color: 'text',
        fontSize: ['md'],
        mb: ['1', '1', '2'],
      },
      blockquote: {
        padding: 6,
        textStyle: 'italic',
        borderLeftStyle: 'solid',
        borderLeftWidth: 'thick',
        borderLeftColor: 'lightgray',
        fontSize: ['lg', 'lg', 'xl'],
        mb: ['3', '3', '6'],
      },
      h1: {
        fontFamily: 'heading',
        fontSize: ['3xl', '3xl', '4xl'],
        fontWeight: '700',
        lineHeight: 1.15,
      },
      h2: {
        fontFamily: 'heading',
        fontSize: ['3xl', '3xl', '5xl'],
        fontWeight: '600',
        lineHeight: 1.15,
        my: ['3', '3', '6'],
      },
      h3: {
        fontFamily: 'heading',
        fontSize: ['xl', 'xl', '2xl'],
        fontWeight: '600',
        lineHeight: 1.15,
        color: 'brand.darkgrey',
        my: ['3', '3', '6'],
      },
      h4: {
        fontFamily: 'heading',
        fontSize: ['lg', 'lg', 'xl'],
        fontWeight: '400',
        lineHeight: 1.15,
        color: 'text',
        my: ['3', '3', '6'],
      },
      '.logo': {
        display: 'block',
        width: '120px',
        padding: '0.5em 0px',
        zIndex: 50,
      },
      '.primary': {
        fill: 'primary',
      },
      '.text-lg': {
        fontSize: ['lg', 'lg', 'xl'],
      },
    },
  },
})

export default theme
